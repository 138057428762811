import { Component, OnInit } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { Globals } from "../../common/global";
import { environment } from "../../../environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as mapboxgl from "mapbox-gl";

@Component({
  selector: "app-track-assets",
  templateUrl: "./track-assets.component.html",
  styleUrls: ["./track-assets.component.css"],
})
export class TrackAssetsComponent implements OnInit {
  constructor(
    private apiCallingService: ApiCallingService,
    private modalService: NgbModal,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.getList();
  }
  modeltitle: any;
  page = 0;
  pageSize = 20;
  data = [];
  totalcount = 0;
  detail: any;
  type: string = "Rent";
  book_type: string = "advance_booking";
  validationHourError: boolean = false;
  rideTime = {
    hour: "00",
    minute: "00",
    maxHours: 0,
    maxMinutes: 0,
    totalMaxMinutes: 0,
  };
  validationError: string = "";
  status: string = "Active";
  map: any;

  getList() {
    this.data = [];
    let body;
    if (this.type == "Rent") {
      body = new HttpParams()
        .set("owner_id", this.Globals.getUser()._id)
        .set("in_use", "true")
        .set("limit", this.pageSize)
        .set("page", this.page)
        .set("type", this.type);
    } else if (this.type == "Advance_Book") {
      body = new HttpParams()
        .set("owner_id", this.Globals.getUser()._id)
        .set("in_use", "true")
        .set("limit", this.pageSize)
        .set("page", this.page)
        .set("type", this.book_type);
    } else {
      body = new HttpParams()
        .set("owner_id", this.Globals.getUser()._id)
        .set("limit", this.pageSize)
        .set("page", this.page)
        .set("type", this.type);
    }
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.TRACK_ASSETS.LIST)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          //this.totalcount =  res.total;
        }
      });
  }

  endRide(data: any) {
    const body = new HttpParams()
      .set("ride_id", data.rideDetails._id)
      .set("end_battery_percentage", data.battery_percentage)
      .set("end_longitude", data.longitude)
      .set("end_latitude", data.latitude)
      .set("is_forced", false);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.TRACK_ASSETS.END_RIDE)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.getList();
          this.closeBtnClick();
        }
      });
  }
  cancelPackage(data: any) {
    const body = new HttpParams().set(
      "booking_purchase_id",
      data.rideDetails._id
    );

    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.TRACK_ASSETS.END_PACK)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.getList();
        }
      });
  }
  loadPage(page: number, type: string = "") {
    this.type = type ? type : this.type;
    this.page = page - 1;
    this.getList();
  }

  changeStatus(status: string) {
    this.status = status;
    this.getList();
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);

    if (title !== "End Ride") {
      setTimeout(() => {
        (mapboxgl as any).accessToken = environment.mapbox.accessToken;
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          zoom: 13,
          center: this.detail.location.coordinates,
        });

        const el = document.createElement("div");
        el.className = "marker";
        let markerUrl;
        if (this.detail.on_lease) {
          markerUrl = "assets/map/rideHire/icon.png";
        } else {
          markerUrl = "assets/map/rideHireRed/icon.png";
        }

        el.style.backgroundImage = `url('${markerUrl}')`;
        el.style.width = `40px`;
        el.style.height = `40px`;
        el.style.backgroundSize = "100%";

        let htmlofPopup;
        if (this.detail.on_lease) {
          htmlofPopup =
            '<img src="' +
            this.detail.lock_image +
            '" width="50"><br>' +
            "<b>" +
            this.detail.lock_name +
            " (" +
            this.detail.lock_type +
            ")</b><br>" +
            "<b>Lease : </b>" +
            this.detail.owner_id.name +
            '<img src="assets/images/battery.png" width="20">' +
            this.detail.battery_percentage +
            "% <br>" +
            "<b>Address : </b>" +
            this.detail.lock_address;
        } else {
          htmlofPopup =
            '<img src="' +
            this.detail.lock_image +
            '" width="50"><br>' +
            "<b>" +
            this.detail.lock_name +
            " (" +
            this.detail.lock_type +
            ")</b><br>" +
            "<b>Rider : </b>" +
            this.detail.used_by.name +
            '<img src="assets/images/battery.png" width="20">' +
            this.detail.battery_percentage +
            "% <br>" +
            "<b>Address : </b>" +
            this.detail.lock_address;
        }

        let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlofPopup);
        new mapboxgl.Marker(el)
          .setLngLat(this.detail.location.coordinates)
          .setPopup(popup)
          .addTo(this.map);
      });
    }
  }

  rideDurationModal(targetModal: NgbModal) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });

    const timeDiff = this.getTimeDifferenceInMinutes(this.detail.updatedAt);

    this.rideTime.totalMaxMinutes = timeDiff.totalMinutes;
    this.rideTime.maxHours = Math.floor(timeDiff.totalMinutes / 60);
    this.rideTime.maxMinutes = timeDiff.totalMinutes % 60;

    this.rideTime.hour = timeDiff.formattedHours;
    this.rideTime.minute = timeDiff.formattedMinutes;
  }

  getTimeDifferenceInMinutes(updatedDateStr: any) {
    const updatedDate = new Date(updatedDateStr);
    const currentDate = new Date();
    const differenceInMs = currentDate.getTime() - updatedDate.getTime();
    const totalMinutes = Math.floor(differenceInMs / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return { formattedHours, formattedMinutes, totalMinutes };
  }
  padNumber(num: number): string {
    return num.toString().padStart(2, "0");
  }
  onHourInput(event: any) {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "").slice(0, 2);
    let value = parseInt(inputValue);

    if (isNaN(value) || value < 0) {
      this.rideTime.hour = "00";
      return;
    }

    if (value > this.rideTime.maxHours) {
      this.rideTime.hour = this.padNumber(this.rideTime.maxHours);
      this.rideTime.minute = "00";
      this.validationHourError = true;
    } else {
      this.rideTime.hour = this.padNumber(value);
      this.validationHourError = false;
      this.validateRideTime();
    }
  }

  onMinuteInput(event: any) {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "").slice(0, 2);
    let value = parseInt(inputValue);

    if (isNaN(value) || value < 0) {
      this.rideTime.minute = "00";
      return;
    }

    if (value >= 60) {
      const additionalHours = Math.floor(value / 60);
      const remainingMinutes = value % 60;

      const newTotalHours = parseInt(this.rideTime.hour) + additionalHours;
      if (newTotalHours > this.rideTime.maxHours) {
        this.rideTime.hour = this.padNumber(this.rideTime.maxHours);
        this.rideTime.minute = "00";
        this.validationError = `Maximum allowed time is ${this.padNumber(
          this.rideTime.maxHours
        )} hours and ${this.padNumber(this.rideTime.maxMinutes)} minutes`;
      } else {
        this.rideTime.hour = this.padNumber(newTotalHours);
        this.rideTime.minute = this.padNumber(remainingMinutes);

        this.validateRideTime();
      }
    } else {
      this.rideTime.minute = this.padNumber(value);
      this.validateRideTime();
    }
  }

  validateRideTime(): boolean {
    const hours = parseInt(this.rideTime.hour);
    const minutes = parseInt(this.rideTime.minute);
    const totalInputMinutes = hours * 60 + minutes;

    if (
      hours > this.rideTime.maxHours ||
      minutes > this.rideTime.maxMinutes ||
      totalInputMinutes > this.rideTime.totalMaxMinutes
    ) {
      this.rideTime.hour = this.rideTime.maxHours.toString();
      this.rideTime.minute = this.rideTime.maxMinutes.toString();
      this.validationError = `Maximum allowed time is ${this.rideTime.maxHours} hours and ${this.rideTime.maxMinutes} minutes`;
      return false;
    }

    this.validationError = "";
    return true;
  }

  submitRideEndTime() {
    if (!this.validateRideTime() || this.validationHourError) {
      this.validationError = `Maximum allowed time is ${this.rideTime.maxHours} hours and ${this.rideTime.maxMinutes} minutes`;
      return;
    } else {
      const hours = parseInt(this.rideTime.hour) || "00";
      const minutes = this.padNumber(parseInt(this.rideTime.minute)) || "00";

      const body = new HttpParams()
        .set("ride_id", this.detail.rideDetails._id)
        .set("end_battery_percentage", this.detail.battery_percentage)
        .set("end_longitude", this.detail.longitude)
        .set("end_latitude", this.detail.latitude)
        .set("is_forced", false)
        .set("end_from_admin", true)
        .set("end_time", `${hours}:${minutes}`);

      console.log("Submitting ride end time:", body);
      this.apiCallingService
        .callAPI(body, "POST", ConfigApi.URLS.TRACK_ASSETS.END_RIDE)
        .subscribe((res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.getList();
            this.closeBtnClick();
          }
        });
    }
  }

  closeBtnClick() {
    if (this.validationError || this.validationHourError) {
      this.validationError = "";
      this.validationHourError = false;
    }
    this.modalService.dismissAll();
  }
}
