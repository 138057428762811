<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title">Track assets</h4>
      </div>
      <ngb-tabset>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <div (click)="loadPage(1, 'Rent')">Rent</div>
          </ng-template>
          <ng-template ngbTabContent>
            <table class="table table-striped border mt-4">
              <thead>
                <tr>
                  <th scope="col">Rider</th>
                  <th scope="col">Lock name</th>
                  <th scope="col">Lock type</th>
                  <th scope="col">Address</th>
                  <th scope="col">Ride start time</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let d of data">
                  <td>{{ d?.used_by?.name }}</td>
                  <td>{{ d?.lock_name }}</td>
                  <td>{{ d?.lock_type }}</td>
                  <td>{{ d?.lock_address }}</td>
                  <td>{{ d?.updatedAt | date : "medium" }}</td>
                  <td>
                    <a
                      href="javascript: void(0);"
                      class="link mr-2"
                      placement="top"
                      (click)="openModal(editModal, d, 'Track Rented Asset')"
                      ngbTooltip="Track"
                    >
                      <i class="mdi mdi-map"></i>
                    </a>
                    <a
                      href="javascript: void(0);"
                      class="link mr-2"
                      placement="top"
                      (click)="openModal(editModal, d, 'End Ride')"
                      ngbTooltip="End Ride"
                    >
                      <!-- (click)="endRide(d)" -->
                      <i class="far fa-stop-circle"></i>
                    </a>
                    <a
                      href="https://immerch.com/{{ d?.used_by?.name }}"
                      target="_blank"
                      class="link mr-2"
                      placement="top"
                      ngbTooltip="Contact"
                    >
                      <i class="fas fa-phone"></i>
                    </a>
                  </td>
                </tr>
                <tr *ngIf="data.length == 0">
                  <td colspan="6">No data found</td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <div (click)="loadPage(1, 'Lease')">Book</div>
          </ng-template>
          <ng-template ngbTabContent>
            <table class="table table-striped border mt-4">
              <thead>
                <tr>
                  <th scope="col">Book</th>
                  <th scope="col">Lock name</th>
                  <th scope="col">Lock type</th>
                  <th scope="col">Address</th>
                  <th scope="col">Book detail</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let d of data">
                  <td>{{ d?.owner_id?.name }}</td>
                  <td>{{ d?.lock_name }}</td>
                  <td>{{ d?.lock_type }}</td>
                  <td>{{ d?.lock_address }}</td>
                  <td>
                    Purchased on
                    {{ d?.lease_subscription?.start_date | date : "medium" }}
                    <br />
                    Expired on
                    {{ d?.lease_subscription?.end_date | date : "medium" }}
                    <br />
                    Auto renewal
                    {{
                      d?.lease_subscription?.is_activated
                        ? "Activated"
                        : "Cancelled"
                    }}
                    <br />
                    {{ d?.lease_subscription?.package_id?.timing }} plan at
                    {{ d?.lease_subscription?.package_id?.price }}
                    {{ d?.lease_subscription?.package_id?.currency?.code }}
                  </td>
                  <td>
                    <a
                      href="javascript: void(0);"
                      (click)="openModal(editModal, d, 'Track Leased Asset')"
                      class="link mr-2"
                      placement="top"
                      ngbTooltip="Track"
                    >
                      <i class="mdi mdi-map"></i>
                    </a>
                    <a
                      href="https://immerch.com/{{ d?.owner_id?.name }}"
                      target="_blank"
                      class="link mr-2"
                      placement="top"
                      ngbTooltip="Contact"
                    >
                      <i class="fas fa-phone"></i>
                    </a>
                  </td>
                </tr>
                <tr *ngIf="data.length == 0">
                  <td colspan="6">No data found</td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <div (click)="loadPage(1, 'Advance_Book')">Advance Book</div>
          </ng-template>
          <ng-template ngbTabContent>
            <table class="table table-striped border mt-4">
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Lock name</th>
                  <th scope="col">Lock type</th>
                  <th scope="col">In_Use</th>
                  <th scope="col">Address</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Total Asset Used</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let d of data">
                  <td>{{ d?.used_by?.name ? d?.used_by?.name : "-" }}</td>
                  <td>{{ d?.lock_name }}</td>
                  <td>{{ d?.lock_type }}</td>
                  <td>{{ d?.in_use }}</td>
                  <td>{{ d?.lock_address }}</td>
                  <td>
                    {{
                      d?.start_date ? (d?.start_date | date : "medium") : "-"
                    }}
                  </td>
                  <td>
                    {{ d?.end_date ? (d?.end_date | date : "medium") : "-" }}
                  </td>
                  <td>{{ d?.total_asset_used ? d?.total_asset_used : "-" }}</td>
                  <td>
                    <a
                      href="javascript: void(0);"
                      class="link mr-2"
                      placement="top"
                      (click)="cancelPackage(d)"
                      ngbTooltip="Cancel Package"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                  </td>
                </tr>
                <tr *ngIf="data.length == 0">
                  <td colspan="9">No data found</td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
      <div class="">
        <!-- <div class="d-flex justify-content-center mt-5">
            <ngb-pagination  [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount"  [maxSize]="7" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
          </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <ng-container *ngIf="modeltitle !== 'End Ride'">
    <div class="modal-body">
      <div class="form-group row">
        <div class="col-sm-12">
          <div id="map" class="map match-parent"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="modeltitle === 'End Ride'">
    <div class="modal-body">
      <div class="text-center">
        <h5>Do you want to change ride duration?</h5>
        <div class="d-grid gap-3 mt-4">
          <button
            type="button"
            class="btn btn-dark btn-lg mr-2"
            (click)="rideDurationModal(rideDuration)"
          >
            Yes
          </button>
          <button
            type="button"
            class="btn btn-dark btn-lg"
            (click)="endRide(detail)"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #rideDuration let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>
    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <ng-container>
    <div class="modal-body">
      <div class="text-center">
        <p style="font-size: 20px">Enter total ride time to be calculated</p>
        <p class="text-muted">
          Maximum allowed time: {{ rideTime.maxHours }} hours and
          {{ rideTime.maxMinutes }} minutes
        </p>
        <div style="display: flex; gap: 10px; justify-content: center">
          <div class="form-group mr-2">
            <label><i class="far fa-clock"></i> Hour</label>
            <input
              type="number"
              [(ngModel)]="rideTime.hour"
              (input)="onHourInput($event)"
              class="form-control text-center"
              min="00"
              [max]="rideTime.maxHours"
              maxlength="2"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </div>
          <div class="form-group">
            <label><i class="far fa-clock"></i> Minute</label>
            <input
              type="number"
              [(ngModel)]="rideTime.minute"
              (input)="onMinuteInput($event)"
              class="form-control text-center"
              min="0"
              max="59"
              maxlength="2"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </div>
        </div>
        <div *ngIf="validationError || validationHourError" class="text-danger">
          {{ validationError }}
        </div>
        <button class="btn btn-dark btn-lg mt-3" (click)="submitRideEndTime()">
          Submit
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
